import React from 'react';
import Article, { SubTitle } from 'components/help/helpArticle';
import Code from 'components/help/codeSnippet';
import Link from 'components/ui/link';
import { DevelopersLink } from '../../components/Link';
import { Information } from '../../components/information';

const Content = () => {
  return (
    <div>
      <SubTitle>How does it work?</SubTitle>
      <p>
        If you have already set up custom events in your Google Analytics account, you can easily
        use them with LiveSession. You can configure LiveSession callback to collect data every time
        the GA tracker sends a custom event.
      </p>
      <p>Edit the code below according to your needs to start tracking GA custom events.</p>
      <Information color="warning">
        Custom Events are available via JS API.{' '}
        <DevelopersLink blank href="/javascript-api/methods/#track">
          Learn
        </DevelopersLink>{' '}
        how to use it.
      </Information>
      <SubTitle className="mt-5">How can I install this integration?</SubTitle>
      <span>
        To install integration, edit your tracking ID (<Code inline>UA-XXXXXXXXX-X</Code>) and add
        the code <strong>after initializing</strong> Google Analytics and LiveSession.
      </span>
      <Code>
        {`<script>
    window.ga =
    window.ga ||
    function () {
        (ga.q = ga.q || []).push(arguments);
    };
    ga.l = +new Date();
    ga("create", "UA-XXXXXXXXX-X", "auto");
    ga("send", "pageview");
    ga(function (tracker) {
    var originalSendHitTask = tracker.get("sendHitTask");
    tracker.set("sendHitTask", function (model) {
        originalSendHitTask(model);
        const eventType = tracker.get("eventCategory");
        if (window.__ls) {
        window.__ls("track", eventType, {
            category: eventType,
            action: tracker.get("eventAction"),
        });
        }
    });
});
</script>`}
      </Code>
      <div className="alert alert-primary mb-5 mt-5" role="alert">
        To make this integration works, you need to have Google Analytics and LiveSession installed
        on your website. If you haven&apos;t installed our tracking code yet check{' '}
        <Link href="/help/how-to-install-livesession-script/">this article</Link>.
      </div>
    </div>
  );
};

const related = [
  {
    link: '/help/google-analytics-integration/',
    title: 'LiveSession and Google Analytics integration',
    description: 'Send LiveSession recordings as events to Google Analytics',
  },
  {
    link: '/help/how-do-the-custom-events-work/',
    title: 'How does the Custom Events work?',
    description: 'Wondering about the usability of our Custom Events? Read here.',
  },
];

export const frontmatter = {
  metaTitle: 'How to connect events from Google Analytics?',
  metaDescription: 'Take a look how to pass Google Analytics events to LiveSession',
  canonical: '/help/how-to-connect-events-from-google-analytics/',
};

const Wrapped = Article(Content);

export default () => (
  <Wrapped related={related} section="Developer" title={frontmatter.metaTitle} {...frontmatter} />
);
